import {Grid} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {MessageInitial, Container, DotImg, ContainerImage} from "./styles";
import ButtonStyled from "../ButtonStyled";
import {isAndroid, isIOS, isSafari} from "react-device-detect";


const handleDownloadAppClick = () => {
    if (isIOS || isSafari) {
        window.open(
            "https://apps.apple.com/br/app/oqtem-food/id1671929768",
            "_blank"
        );
        return;
    }

    window.open(
        "https://play.google.com/store/apps/details?id=com.oqtem.cliente",
        "_blank"
    );
};

export default function Initial() {
    const isMobile = useMediaQuery("(min-width:1200px)");

    return (
        <Container>
            <Grid
                display="flex"
                container
                py={2}
                position={"relative"}
                justifyContent={"center"}
                marginBottom={isMobile ? "-30px" : ""}
            >
                <Grid
                    item
                    xs={11.5}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    alignSelf={"center"}
                    textAlign={{
                        xs: "center",
                        sm: "center",
                        md: "center",
                        lg: "center",
                        xl: "center",
                    }}
                >
                    <Grid
                        textAlign={{
                            xs: "center",
                            sm: "center",
                            md: "center",
                            lg: "left",
                            xl: "left",
                        }}
                    >
                        <MessageInitial>A única plataforma de</MessageInitial>
                        <MessageInitial>delivery do Brasil que devolve</MessageInitial>
                        <MessageInitial color="#efffad">
                            cashback em sua conta
                        </MessageInitial>
                        <ButtonStyled onClick={handleDownloadAppClick}>BAIXAR O APP</ButtonStyled>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={5}
                    xl={5}
                    pt={isMobile ? 0 : 8}
                    textAlign={{
                        xs: "center",
                        sm: "center",
                        md: "center",
                        lg: "center",
                        xl: "center",
                    }}
                >
                    <img
                        src="/images/woman_phone_pizza.png"
                        alt="logo"
                        width={1024}
                        height={1024}
                        style={{
                            width: isMobile ? "80%" : "60%",
                            height: "auto",
                            marginBottom: "-20px",
                            minWidth: "370px",
                        }}
                    />
                </Grid>
            </Grid>
            <ContainerImage>
                <img
                    src="/images/dot_initial.png"
                    alt="logo"
                    width={500}
                    height={500}
                    style={DotImg}
                />
            </ContainerImage>
        </Container>
    );
}
