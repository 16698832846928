import {Button} from "@mui/material";
import {ButtonStyle} from "./styles";

export default function ButtonStyled(props: any) {
    return (
        <Button
            onClick={props.onClick}
            disabled={false}
            size="large"
            variant="contained"
            style={ButtonStyle(props)}
        >
            {props.children}
        </Button>
    );
}
