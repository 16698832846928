import {Box, Grid} from "@mui/material";
import {useState} from "react";
import {isAndroid, isIOS, isSafari} from "react-device-detect";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Title, SubTitle, DownloadText} from "./styles";

export default function AppNotification() {
    const [open, setOpen] = useState(true);

    const handleClick = () => {

        if (isIOS || isSafari) {
            window.open(
                "https://apps.apple.com/br/app/oqtem-food/id1671929768",
                "_blank"
            );

            return;
        }

        if (isAndroid) {
            window.open(
                "https://play.google.com/store/apps/details?id=com.oqtem.cliente",
                "_blank"
            );

            return;
        }


        window.open(
            "https://play.google.com/store/apps/details?id=com.oqtem.cliente",
            "_blank"
        );

    };
    return (
        <Box pt={3} pb={2.5} px={1} display={open === false ? "none" : ""}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={2.5} sm={2} md={2} textAlign="center">
                    <img
                        style={{width: "60px", height: "60px"}}
                        src="images/app_icon.svg"
                        alt="icon"
                    />
                </Grid>
                <Grid display="grid" item xs={5} sm={5} md={5} textAlign="left">
                    <Title>OQtem</Title>
                    <div style={{padding: "3px 0px"}}>
                        {Array.from(new Array(5)).map((_, index) => (
                            <img
                                key={index}
                                style={{width: "13px", height: "13px"}}
                                src="images/star.png"
                                alt="star_icon"
                            />
                        ))}
                    </div>
                    <SubTitle>Baixe agora</SubTitle>
                </Grid>
                <Grid item xs={3} sm={3} md={3} textAlign="center">
                    <DownloadText onClick={handleClick}>Ver App</DownloadText>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} textAlign="right" pb={5.5}>
                    <IconButton
                        aria-label="close"
                        size="small"
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon style={{width: "20px", height: "20px"}}/>
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
}
