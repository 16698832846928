import {Grid, Box} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CountUp from "react-countup";
import {DottedTest, TextCounter, TextLabel} from "./styles";

export default function Counters() {
    const isMobile = useMediaQuery("(min-width:1200px)");
    return (
        <Box pb={4}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid
                    item
                    xs={9}
                    sm={8}
                    md={isMobile ? 4 : 7}
                    xl={3}
                    py={2}
                    justifyContent="center"
                    textAlign="center"
                >
                    <DottedTest
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div>
                            <img
                                src="/images/counter_one.svg"
                                alt="logo"
                                width={isMobile ? 80 : 59}
                                height={isMobile ? 80 : 59}
                            />
                        </div>
                        <Box
                            display="grid"
                            justifyContent="left"
                            paddingLeft={"25px"}
                            width="250px"
                        >
                            <TextCounter>
                                <Box display="flex" alignItems="center">
                                    +
                                    <CountUp
                                        start={0}
                                        end={200}
                                        delay={1}
                                        duration={5}
                                        enableScrollSpy={true}
                                    />
                                </Box>
                            </TextCounter>
                            <TextLabel>parceiros cadastrados</TextLabel>
                        </Box>
                    </DottedTest>
                </Grid>
                <Grid item xs={9} sm={8} md={isMobile ? 4 : 7} xl={3} py={2}>
                    <DottedTest
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div>
                            <img
                                src="/images/counter_two.svg"
                                alt="logo"
                                width={isMobile ? 80 : 59}
                                height={isMobile ? 80 : 59}
                            />
                        </div>
                        <Box
                            display="grid"
                            justifyContent="left"
                            paddingLeft={"25px"}
                            width="250px"
                        >
                            <TextCounter>
                                <Box display="flex" alignItems="center">
                                    +
                                    <CountUp
                                        start={0}
                                        end={10000}
                                        delay={1}
                                        duration={5}
                                        enableScrollSpy={true}
                                    />
                                    <span style={{marginLeft: "5px"}}>mil</span>
                                </Box>
                            </TextCounter>
                            <TextLabel>pedidos realizados</TextLabel>
                        </Box>
                    </DottedTest>
                </Grid>
                <Grid item xs={9} sm={8} md={isMobile ? 4 : 7} xl={3} py={2}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <div>
                            <img
                                src="/images/counter_three.svg"
                                alt="logo"
                                width={isMobile ? 80 : 59}
                                height={isMobile ? 80 : 59}
                            />
                        </div>
                        <Box
                            display="grid"
                            justifyContent="left"
                            paddingLeft={"25px"}
                            width="250px"
                        >
                            <TextCounter>
                                <Box display="flex" alignItems="center">
                                    +
                                    <CountUp
                                        start={0}
                                        end={3}
                                        delay={1}
                                        duration={5}
                                        enableScrollSpy={true}
                                    />
                                </Box>
                            </TextCounter>
                            <TextLabel>cidades de atuação</TextLabel>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
